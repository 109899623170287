import { useEffect, useState, useRef } from 'react';
import { useSettings } from '@backpackjs/storefront';
import Link from 'next/link';
import AccountLayout from '../../layouts/AccountLayout';

export function Referral() {
  const settings = useSettings();
  const referralRef = useRef(null);
  const [loaded, setLoaded] = useState(false);

  let i = 0;
  const buildReferral = () => {
    i++;
    if (
      i > 6 ||
      document.querySelector(
        '.account-referral .swell-referral-content-container'
      )
    ) {
      return;
    }
    if (typeof Swell === 'undefined') {
      const t = window.setTimeout(() => {
        buildReferral();
        clearTimeout(t);
      }, 500);
      return;
    }

    Swell.Referral.initializeReferral('.account-referral', {
      localization: {
        referralRegisterHeading: 'Refer A Friend',
        referralCopyLinkHeading: 'Copy Link',
        referralReferHeading: 'Refer A Friend',
        referralRegisterFormDetails: 'Enter your email below.',
      },
    });
  };

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (referralRef.current) {
      buildReferral();
    }
    document.addEventListener('swell:setup', buildReferral);
    return () => {
      document.removeEventListener('swell:setup', buildReferral);
    };
  }, [loaded, referralRef]);

  return (
    <AccountLayout>
      <div className="px-4 pt-3 pb-10 lg:p-0">
        <Link href="/account/orders">
          <a className="focus-link text-small mb-3 lg:hidden">
            Back to Order History
          </a>
        </Link>
        <h3 className="mb-8 lg:mb-7">Refer A Friend</h3>
        <div className="flex flex-wrap">
          {settings?.account?.referFriendImage && (
            <div className="mb-9 w-full md-:mb-0 md-:w-1/2">
              <img
                src={settings.account.referFriendImage.src}
                alt={settings.account.referFriendImage.alt}
              />
            </div>
          )}
          <div
            className="account-referral w-full md-:w-1/2"
            ref={referralRef}
          />
        </div>
      </div>
    </AccountLayout>
  );
}
