import { useMemo } from 'react';
import { useCustomerLogin, useSettings } from '@backpackjs/storefront';
import { useDataLayerActions } from '@hooks';

export function LoginForm({ setAction }) {
  const {
    customerLogin,
    started,
    finished,
    errors,
    success,
    reset,
    resetTimer,
  } = useCustomerLogin();
  const { sendLogInEvent } = useDataLayerActions();

  const settings = useSettings();

  const buttonText = useMemo(() => {
    if (finished) {
      resetTimer.current = setTimeout(() => {
        reset && reset();
        clearTimeout(resetTimer.current);
      }, 2500);
    }

    return finished
      ? success
        ? 'Signed In'
        : 'Failed'
      : // not finished yet
      started
      ? 'Signing in...'
      : 'Sign In';
  }, [started, success, finished]);

  const loginHandler = async (event) => {
    event.preventDefault();
    const { email, password } = event.target;

    await customerLogin({
      email: email.value,
      password: password.value,
    });

    sendLogInEvent();
  };

  return (
    <div className="px-6 py-[50px] lg:py-20">
      {settings?.account?.SignInMessage && (
        <div className="mx-auto mb-6 max-w-[325px] md:max-w-[430px]">
          <div className="flex items-start justify-center">
            <img className="mt-1 h-4 w-4" src="/images/success-icon.png" />
            <p className="m-0 pl-2 text-center text-teal-50">
              {settings?.account?.SignInMessage}
            </p>
          </div>
        </div>
      )}
      <form
        className="mx-auto w-full max-w-[325px] text-center"
        onSubmit={loginHandler}
      >
        <h3 className="mb-3 lg:mb-4">Sign In</h3>
        <p className="text-small mb-7 font-light lg:text-base">
          Don’t have an account? <a href="/account/register">Create one now.</a>
        </p>

        {errors?.length > 0 && (
          <div className="relative mb-[22px] w-full px-7 py-6">
            <span className="absolute inset-0 h-full w-full bg-error opacity-10" />
            {errors.map((err) => (
              <p className="mb-0 text-[14px] leading-5 text-error">
                {err === 'Unidentified customer'
                  ? 'Incorrect email / password'
                  : 'Something went wrong. Please try again.'}
              </p>
            ))}
          </div>
        )}

        <div className="form-field mb-[22px]">
          <input
            id="loginEmail"
            placeholder=" "
            name="email"
            type="email"
            required
          />
          <label htmlFor="loginEmail"> Email Address </label>
        </div>
        <div className="form-field mb-7">
          <input
            id="loginPassword"
            placeholder=" "
            name="password"
            type="password"
            required
          />
          <label htmlFor="loginPassword"> Password </label>
        </div>

        <button
          type="submit"
          className="button button--primary mb-[22px] w-[225px]"
          disabled={started || (finished && success)}
        >
          <span className="text-base">{buttonText}</span>
        </button>

        <button
          type="button"
          className="focus-link cursor-pointer"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setAction('forgot');
          }}
        >
          Forgot Password
        </button>
      </form>
    </div>
  );
}
